export const UPLOAD_PATHS = {
  eventCover: () => `events/cover`,
  eventPerson: (eventId: string) => `events/${eventId}/person`,
  regionCover: () => `regions/covers`,
  regionPerson: (regionId: string) => `regions/${regionId}/person`,
  regionPartner: (regionId: string) => `regions/${regionId}/partner`,
  regionFinancer: (regionId: string) => `regions/${regionId}/financer`,
  competitionPerson: (competitionId: string) => `competitions/${competitionId}/person`,
  competitionPartner: (competitionId: string) => `competitions/${competitionId}/partner`,
  eventContent: () => `events/content`,
  userAvatar: (userId: string) => `users/${userId}/avatar`,
  competitionThumbnail: () => `competitions/thumbnail`,
  feedbackUpload: (actId: string) => `acts/${actId}/feedback`,
  projectCover: () => `projects/cover`,
  projectPdf: () => `projects/pdf`,
  customFeedImage: () => `customFeed/image`,
  actAttachment: (actId: string) => `acts/${actId}/attachments`,
  pinkPopPageForm: () => "pages/pinkpop",
}
