import React from "react"
import type { DropzoneOptions, FileRejection } from "react-dropzone"
import { useDropzone } from "react-dropzone"
import type { FieldError } from "react-hook-form"
import { AiOutlineEye } from "react-icons/ai"
import { BiEdit, BiFile, BiTrashAlt } from "react-icons/bi"
import type { BoxProps } from "@chakra-ui/react"
import {
  Box,
  Center,
  Flex,
  FormControl,
  HStack,
  IconButton,
  Text,
  Tooltip,
  useColorModeValue,
} from "@chakra-ui/react"

import { useFormContext } from "lib/hooks/useForm"
import { useToast } from "lib/hooks/useToast"

import { InputError } from "./InputError"
import { InputLabel } from "./InputLabel"

const DEFAULT_MAX_SIZE = 1024 * 1024 * 50 // 50MB

interface Props extends Omit<BoxProps, "onDrop"> {
  name: string
  label?: string
  isRequired?: boolean
  isDisabled?: boolean
  subLabel?: string
  dropzoneOptions?: Omit<DropzoneOptions, "multiple" | "onDrop">
  acceptAll?: boolean
}

export const FileInput: React.FC<Props> = ({
  name,
  label,
  subLabel,
  isDisabled,
  isRequired,
  dropzoneOptions,
  acceptAll = false,
  ...props
}) => {
  const {
    register,
    setValue,
    watch,
    clearErrors,
    formState: { errors },
  } = useFormContext()
  const toast = useToast()
  const fieldError = errors?.[name] as FieldError | string

  React.useEffect(() => {
    register(name)
  }, [register, name])

  const file = watch(name) as File | string

  const onDrop = React.useCallback(
    (files: File[], rejectedFiles: FileRejection[]) => {
      if (rejectedFiles.length > 0) {
        const rejectedFile = rejectedFiles[0]
        if (rejectedFile.errors[0]?.code.includes("file-too-large")) {
          const description = `Bestand te groot, moet kleiner zijn dan ${
            (dropzoneOptions?.maxSize && `${dropzoneOptions.maxSize / (1024 * 1024)}MB`) ||
            `${DEFAULT_MAX_SIZE / (1024 * 1024)}MB`
          }`
          toast({ status: "error", title: "Ongeldig bestand", description })
        } else {
          // TODO: add remaining error handlers
          toast({ status: "error", description: "Ongeldig bestand, probeer een andere" })
        }
        return
      }
      if (files.length === 0) return
      const file = files[0]
      clearErrors(name)
      setValue(name, file, { shouldDirty: true })
    },
    [toast, dropzoneOptions, name, setValue, clearErrors],
  )

  const { getRootProps, getInputProps } = useDropzone({
    maxSize: DEFAULT_MAX_SIZE,
    ...dropzoneOptions,
    onDrop,
    multiple: false,
    accept: acceptAll
      ? undefined
      : dropzoneOptions?.accept || {
          "application/pdf": [".pdf"],
        },
  })

  const bg = useColorModeValue("gray.100", "gray.700")
  const editColor = useColorModeValue("gray.900", "gray.50")

  return (
    <FormControl isRequired={isRequired} isInvalid={!!fieldError}>
      <InputLabel label={label} subLabel={subLabel} name={name} />
      <Center
        w="100%"
        h="100px"
        overflow="hidden"
        borderColor={!!fieldError ? "red.500" : "gray.200"}
        textAlign="center"
        color="gray.500"
        bg={bg}
        border="2px dashed"
        _hover={{ opacity: isDisabled ? undefined : 0.8 }}
        {...props}
      >
        {file ? (
          <HStack spacing={1} p={4} h="100%" justify="center" wrap="wrap">
            <Box {...getRootProps()} outline={isDisabled ? "none" : undefined}>
              <HStack spacing={2} cursor={isDisabled ? "default" : "pointer"}>
                <input disabled={isDisabled} {...getInputProps()} />
                <FilePreview file={file} />
                <Tooltip label="Bestand bewerken">
                  <IconButton
                    isDisabled={isDisabled}
                    aria-label="bestand bewerken"
                    icon={<Box as={BiEdit} color={editColor} />}
                    size="sm"
                  />
                </Tooltip>
              </HStack>
            </Box>
            {file && typeof file === "string" && (
              <Tooltip label="Bestand bekijken">
                <IconButton
                  aria-label="bestand bekijken (wordt geopend in een nieuw tabblad)"
                  icon={<Box as={AiOutlineEye} color="pink.500" />}
                  size="sm"
                  onClick={() => window.open(file, "_blank")}
                />
              </Tooltip>
            )}
            <Tooltip label="Bestand verwijderen">
              <IconButton
                aria-label="bestand verwijderen"
                icon={<Box as={BiTrashAlt} />}
                size="sm"
                onClick={() => setValue(name, null, { shouldDirty: true })}
                colorScheme="red"
              />
            </Tooltip>
          </HStack>
        ) : (
          <Center
            {...getRootProps()}
            outline={isDisabled ? "none" : undefined}
            cursor={isDisabled ? "default" : "pointer"}
            color="gray.500"
            w="100%"
            h="100%"
          >
            <input disabled={isDisabled} {...getInputProps()} />
            Upload je bestand
          </Center>
        )}
      </Center>
      <InputError error={fieldError} />
    </FormControl>
  )
}

interface FileProps {
  file: File | string
}

function FilePreview(props: FileProps) {
  return (
    <Flex p={2} bg={useColorModeValue("white", "gray.700")} align="center" boxShadow="sm" rounded="full">
      <Box as={BiFile} boxSize="16px" color="pink.500" mx={2} />
      <Text maxW="160px" isTruncated color={useColorModeValue("gray.800", "gray.200")} pr={2}>
        {typeof props.file === "string" ? props.file.split("/").pop() : props.file?.name}
      </Text>
    </Flex>
  )
}
