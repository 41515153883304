import * as yup from "yup"

yup.addMethod(yup.string, "nullIfEmpty", function () {
  return this.transform((val, origVal) => (origVal === "" || origVal?.trim() === "" ? null : val)).nullable()
})
yup.addMethod(yup.number, "nullIfEmpty", function () {
  return this.transform((val, origVal) => (origVal === "" ? null : val)).nullable()
})
yup.addMethod(yup.date, "nullIfEmpty", function () {
  return this.transform((val, origVal) => (origVal === "" ? null : val)).nullable()
})
yup.addMethod(yup.array, "nullIfEmpty", function () {
  return this.transform((val, origVal) => (origVal && origVal.length > 0 ? null : val)).nullable()
})
yup.addMethod(yup.mixed, "nullIfEmpty", function () {
  return this.transform((val, origVal) => origVal || val).nullable()
})

yup.addMethod(yup.mixed, "uploader", function () {
  return this.transform((val, origVal) =>
    origVal && typeof origVal === "string" && origVal.startsWith("https://")
      ? origVal.replace(/.*\.(net|com)\//, "")
      : val || null,
  ).nullable()
})

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const UploadSchema = yup.mixed().uploader()

export default yup
